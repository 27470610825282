<template>
    <div class="w-full h-full flex flex-col items-center justify-between">
        <Head title="Index" />
        <div class="size-32 bg-transparent"></div>
        <div class="text-center">
          <h1 class="text-7xl font-extrabold text-white mb-4">FAZ MATCH COM</h1>
          <p>
            <span class="text-7xl mb-2" :style="'color:' + textColor1">{{ textArtist1 }}</span><span v-html="textArtist1.length > textArtist2.length ? '<br>' : '&nbsp;'"></span><span class="text-7xl" :style="'color:' + textColor2">&</span><span v-html="textArtist1.length <= textArtist2.length ? '<br>' : '&nbsp;'"></span><span class="text-7xl" :style="'color:' + textColor3">{{ textArtist2 }}</span>
          </p>
        </div>
        <Link href="/register" class="bg-white text-black font-extrabold py-2 px-8 text-4xl skew-x-[-9.73deg] hover:bg-black hover:text-white">PLAY</Link>
    </div>
  </template>
  
  <script setup>
    import { ref } from 'vue'
    import { Head, Link } from '@inertiajs/vue3'
    import { useNavStore } from '@/stores/nav';
    import { useColors } from '@/composables/useColors';
    import { useArtists } from '@/composables/useArtists';

    import MainLayout from '@/Layouts/MainLayout.vue';
    defineOptions({ layout: MainLayout })
  
    const navStore = useNavStore();
  
    const { getRandomColors } = useColors()
    const { getRandomArtists } = useArtists()
    const textColor1 = ref('')
    const textColor2 = ref('')
    const textColor3 = ref('')
    const textArtist1 = ref('')
    const textArtist2 = ref('')
  
    console.log('bg', navStore.bg)
  
    const [text1, text2, text3] = getRandomColors(3, navStore.bg)
    textColor1.value = text1
    textColor2.value = text2
    textColor3.value = text3
    
    const [artist1, artist2] = getRandomArtists(2)
    textArtist1.value = artist1
    textArtist2.value = artist2
  </script>