import { ref } from 'vue'

export function useArtists() {
    const artists = ref([
      'DUA LIPA',
      'ARCADE FIRE',
      'TYLA',
      'T-REX',
      'BLASTED MECHANISM',
      'PEARL JAM',
      'THE SMASHING PUMPKINS',
      'NOTHING BUT THIEVES',
      'BENJAMIN CLEMENTINE',
      'ASHNIKKO',
      'THE BREEDERS'
    ])
  
    function getRandomArtists(count: number) {
      const shuffledArtists = [...artists.value].sort(() => 0.5 - Math.random());
      return shuffledArtists.slice(0, count);
    }
  
    return {
      artists,
      getRandomArtists
    }
  }